import { useState } from 'react'
export const useLocalStorage = (key, defaultValue) => {
  const [storedvalue, setStoredvalue] = useState(() => {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value)
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (e) {
      return defaultValue;
    }
  })

  const setValue = newValue => {
    try {
      localStorage.setItem(key,JSON.stringify(newValue));
    } catch (e) {
      console.log(e)
    }
    setStoredvalue(newValue)
  }

  return [storedvalue,setValue]

}